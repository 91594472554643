.bubbly-button:active:hover {
color:#ccc;
animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: bounce_cart;
    animation-timing-function: linear;
    width:40px;
    height:40px;
}
@keyframes bounce_cart {
   0%, 20%, 50%, 80%, 100% {scale: 0.9;}
   40% {scale: 0.8;}
   60% {scale: 1.0;}
}
.bubbly-button{

width:40px;
height:40px;
position:absolute;

}
.flip-card-front .material-icons{


color:#ed7d31;
}
