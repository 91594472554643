.flip-card{
margin-top:10px;
padding:10px;
border-radius:10px;
background:#fff;
zIndex:999;

    text-shadow:2px 10px 10px #e8e7ec!important;

}
.footer-links{

background:#fff;
width:100%;
padding:10px;
border-radius:10px;
margin-top:10px;
}
.art2{

}