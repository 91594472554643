
body,
html {
    width: 100%;
    height: 100%;
}
.messageinput {

width:100%;
position:absolute;
bottom:0;
left:0;
height:30px;
max-height:30px;
padding-right:50px;
padding-left:2px;
border-radius:0px;
border-top:1px #ccc solid;
z-index:8;
}
.messages-holder{
    overflow-y:auto;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    width:100%;
padding:4px;
z-index:4;
position:absolute;
left:0;
width:100%;
top:0;
padding-bottom:34px;
}
.top-menu{

position:absolute;
top:10px;
left:10px;

}
.account-bttn {


    padding:10px;
    font-size: .81rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    margin: 0.375rem;
    border: 0;
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
    cursor: pointer;
    text-transform: uppercase;
    white-space: normal;
    color: #00;
margin:auto;
    width:50px;
    text-align:center;

    border-radius:10px;
    position:absolute;
    right:40px;
    border-radius:20px;
    top:10px;
    height:20px;
}
.shipping_country{
font-size:12px;

}
.language-main-menu{


}

.messages-title{

margin-top:20px;
    text-transform: uppercase;
     cursor: pointer;
         -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.material-icons.send{

    position:absolute;
    top:0;
    bottom:0;
    left:0;right:0;
    margin:auto;
    width:20px;
    height:20px;
    font-size:20px;

}
.messageinput::placeholder {
padding-left:2px;
                /* Firefox, Chrome, Opera */
                padding-right: 100px;
            }
.messagebox{
position:relative;
width:100%;
padding:8px;
border:2px #efefef solid;
height:300px;
border-radius:4px;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f3f3f3+50,ffffff+100 */
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(243,243,243,1) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

}
.messagebox .SubmitButton{
z-index:8;
position:absolute;
bottom:0;right:0;
width:50px;
right:0;
height:29px;
margin:0!important;

border-radius:0;
}
.material-icons.checkout-close{

 border-radius: 30px;
    color:#fff;
    font-size: 20px;
    cursor: pointer;
    color:#ed7d31;
    border:1px solid #e8e7ec;
    font-size:40px;
    margin:10px;
    display:table-caption;
    width:44px;
    height:44px;
}

#float-search{

display:none;

}
.testmode{
    position:absolute;
    padding:4px;
    font-weight:400;
    color:#bb5504!important;
    z-index:1;
    font-size: 11px;

    display: flex;
    padding: 2px 4px;
    border-radius: 0px;
background-color: #ffde92;
right:0;
top:60px;;
border:1px dashed rgba(0,0,0,0.1);;
border-right:0;


}
.lineitem td{
vertical-align:middle;
}
.main-menu{

text-transform:uppercase;
}


.averagecount{



}
.order-holder{

margin-bottom:20px;
}
.material-icons.orderslabel{

float:right;

}
.orders{
text-align: left;
    padding: 10px;
    background: #efefef;
    /* border-radius: 21px; */
    text-transform: uppercase;

text-align:left;
}
.shop_info{
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(243,243,243,1) 50%, rgba(237,237,237,1) 51%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 50%,rgba(237,237,237,1) 51%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 50%,rgba(237,237,237,1) 51%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
border-radius:4px;
border:1px #efefef solid;
padding:4px;
margin-bottom:14px;
max-width:280px;
margin:auto;
}
.material-icons.info{
color:#333;
cursor:pointer;
}
.shop_info table{

margin:auto;

}
.shop_info table{

padding:10px;

border-radius:10px;
}
.shop_info td{
text-align:center;
}
.share-links{
  display: block;
  max-width:400px;
  margin:auto;
}
.summary_table{
    width:100%;
}
.pn{

width:100%;
}
body {
    font-family: Nunito!important;
    font-size: 16px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}
a {
    color: var(--primary-color);
}
h1 {
    font-size: 2em;
    margin: .67em 0;
}
.checkbox label {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    cursor: pointer;
}
.checkbox label .box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: var(--light-text);
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    background-color: var(--white-background-color);
    border: 1px solid #c5c5c5;
}
.checkbox label .box .icon {
    display: none;
}
.checkbox.checked label .box {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.checkbox.checked label .box .icon {
    display: block;
}
::-moz-placeholder {
    color: #ccc;
    opacity: 1;
}
:-ms-input-placeholder {
    opacity: 1;
}
::placeholder {
    color: #ccc;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #ccc;
}
::-ms-input-placeholder {
    color: #ccc;
}
.postcode-holder label{
    width:100%;
}
.postcode-holder{
    display: block;
}
.button {
    width: 100%;
    height: 48px;
    line-height: 48px;
    border-radius: 10px;
    background-color: var(--primary-color);
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    margin: 1.5em 0;
    color: var(--light-text);
    font-weight: 700;
    padding: 0;
}
.button:active {
    background-color: var(--primary-color-active);
}
.content-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--grey-background-color);
}
.login-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.card {
    position: relative;
    background-color: rgba(255,255,255 ,1)!important;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0 2px 24px rgba(59, 59, 59, 0.4);
    margin-bottom:100px;
}
.label.goback .edit-checkout.home{


}

.ratingfields label{


text-transform: uppercase;
font-size:12px;
}
.ratingfields{


width: 308px;
    margin: auto;
    text-align: center;
}

.card.shop {
    padding-top: 50px;
    width: 100%;
    max-width:600px;
    text-align: center;

    margin: auto;
    margin-top: 80px;
 margin-bottom:100px;
}
.phoneform.voucher .housenr-field{
    min-width:200px;
}
html{



}
.details{

}
.details .material-icons{
color:#ccc;
font-size:30px;
}
.tax-amount .add-voucher-link{

}

.floating-cart .productname{

font-size:12px;
}
.phoneform.voucher{

width:246px;
margin:auto;
text-align:center;
    padding: 10px;

    padding-left:0px;
}
.minimum-price-amount.shipping,.tax-amount,.voucher-label{
text-align: left;
    margin:8px;
display:flex;
}
.tax-amount{

padding:10px;

}
.voucher-label .material-icons{
    float:left;

    font-size:40px;
}
.tax-amount,.voucher-label{
    font-size: 14px;
    font-weight: normal;
}
.tax-amount .material-icons{
font-size:12px;
}
.image-container img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}
.voucher-label{
    display: flex;
}
.image-container.logo {
    width: 80px;
    position: absolute;
    left: calc(50% - 40px);
    top: -40px;
    border-radius: 10px;
    overflow: hidden;
    border:2px solid #e8e7ec;
    background-color:#fff;
}
.logo{

    background-image:url(https://www.qrdeliver.com/media/qrdelivergif.webp);
    background-repeat:no-repeat;
    background-align:center center;
    background-size:75px auto;
      width: 72px;
 height: 80px;
}
.productimg{
    width: 80px;
 height: 80px;
    left: calc(50% - 40px);

    border-radius: 10px;
    overflow: hidden;
    border:2px solid #e8e7ec;

    background-repeat:norepeat;
    background-align:center center;
    background-size:76px auto;
    opacity:0.4;
animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
background-size:100% 100%;
     background-image:url(https://www.qrdeliver.com/media/qrdelivergif.webp);
}
.subcat{

background:#efefef;
padding:4px;

border-top-left-radius:10px;
}
#bg{

-webkit-transition: 4s -webkit-filter linear;
-moz-transition: 4s -moz-filter linear;
-moz-transition: 4s filter linear;
-ms-transition: 4s -ms-filter linear;
-o-transition: 4s -o-filter linear;
transition: 4s filter linear, 1s -webkit-filter linear;
transition:  4s filter linear, 1s -webkit-filter linear;
  animation-delay: 3.3s;
}
#bg.loaded{
    -webkit-transition: filter 4s; /* Safari */
transition:  4s;
  filter: brightness(1.2) blur(10px) ;
  animation-delay: 3.3s;
}
 #bg .top{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:40px;
  background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 31%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 31%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 31%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

    }
.productimg.loaded{
  background-image:none!important;
  opacity:1.0;
  animation-name: none!important;
}
.productsTable{
    width:100%;
    margin-bottom:10px;
}
.productname{

    width:80%;

}
.productActions td{
  text-align: center;
width:50%;
}
.productActions{
    margin: auto;
    width:120px;
}
.pref-pickup-label{
    margin-top:18px;
}
.disabled{

}
.productsTable table{
    width: 100%;
}
.countryHolder{
    position: relative;
}
.optionsHolder{
  position: relative;
}
.optionHolder.closed{
  display: none;
}
.postcode-field.addition,.postcode-field.city{
    font-size:14px;
    font-weight: normal;
}
.pickup-check{
    display: block;
    font-size: 18px;
    cursor: pointer;
    position: relative;
text-transform: uppercase;
    text-shadow:1px 1px 1px #fff;
}
.productrow.outofstock{
    opacity:0.3;
}
.productrow {

    -webkit-transition: all 0.3s; /* Safari */
  animation: fadein 0.3s;
  animation-iteration-count: 1;
width:100%;
}
.productrow.outofstock *{
    pointer-events: none;
}
.street-field.comments{
    max-width: 308px;
    min-width: 308px;
    max-height: 50px;
    min-height: 50px;
}
.pickup-check .material-icons{
    font-size: 24px;
    color: #000;
    float: right;

    text-shadow:1px 1px 1px #fff;
    position: absolute;
    top:-7px;right:0;

}
.postcode-field.city{
    margin-left: 10px;
}

.drop-country-flag{
    position: absolute;
    border: none!important;
    top:0px;
    bottom: 0;
    margin:auto;
    font-size: 30px;
}

.countriesHolder,.languages-drop{
    position: absolute;top:40px;
    background: #ccc;
    width: 100%;
    z-index: 999;
    box-shadow: -2px 1px 14px 1px rgba(0,0,0,0.4);
    -webkit-box-shadow: -2px 1px 14px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: -2px 1px 14px 1px rgba(0,0,0,0.4);
    max-height: 300px;
    overflow-y:auto;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,f2f2f2+100 */
    background: rgb(238,238,238); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(238,238,238,1) 0%, rgba(242,242,242,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(238,238,238,1) 0%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(238,238,238,1) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#f2f2f2',GradientType=0 ); /* IE6-9 */
border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border:1px solid white;
    border-top: none;

}
.languages-drop{
font-size:12px;
background:#fff!important;

}
.countryLabel.lang{

background: #efefef;
padding:4px;
border-radius:4px;
}
.languages-drop{


padding:10px;
width:140px;
position:absolute;
right:0;
top:0;
}
.countriesHolder .cc-name{
    padding:10px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px #ccc solid;


}
.cc{
    position: relative;
}
.countriesHolder .cc-name:hover{
    background: #fff;
}
.productsTable tr td.product{
    box-shadow: -2px 1px 14px -1px rgba(0,0,0,0.1);
    -webkit-box-shadow: -2px 1px 14px -1px rgba(0,0,0,0.1);
    -moz-box-shadow: -2px 1px 14px -1px rgba(0,0,0,0.1);
    border-radius: 10px;
    overflow: hidden;
    padding: 4px;
    border:1px #ccc solid;

}
.SubmitButton:disabled{

background-color:#efefef!important;
color:#333;

}
.productsTable tr td.product * td.product{

    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    border-radius: 0px;
    overflow: hidden;

    border:none;

}
.countryLabel.lang{

font-size:12px;
text-transform:uppercase;
}
.product {

    animation: slideUpIn 0.4s;
    animation-iteration-count: 1;
position:relative;

}
.share-links button{
  opacity: 1;
}
.share-links button{
    -webkit-transition: all 4s; /* Safari */
  animation: fadeinIcons 0.3s;
  animation-iteration-count: 1;


}
.qrview{
overflow: hidden;
  -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.postcode-holder div,.climate-holder.mini, .SubmitButton{

  -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.qrview canvas{
  -webkit-transition: all 2s; /* Safari */
animation: fadeinIconsQR 0.3s;
animation-iteration-count: 1;
padding: 4px;

}
.share-links button {
  border-radius: 40px;
height: 32px;
background-color: #ed7d3152!important;
margin: 14px;

}
@keyframes fadeinIcons {
    0% { opacity: 0 }
    100% { opacity: 1}
}
@keyframes fadeinIconsQR {
    from { opacity:0;width: 80px;height:80px; }
    to { opacity:1;width: 128px;height: 128px;}
}
.payment-method:hover{
    box-shadow: -2px 1px 14px -1px rgba(0,0,0,0.1);
    -webkit-box-shadow: -2px 1px 14px -1px rgba(0,0,0,0.1);
    -moz-box-shadow: -2px 1px 14px -1px rgba(0,0,0,0.1);

}
.checkout-form{

  background: #e8e7ec57;
   -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;

   border:3px double #fbfbfb;





}
.reduction-amount{
    margin-top: 10px;
}
.payment-methods{
    background: #e8e7ec57;
}
.react-time-picker__wrapper{
    background: #fff;
}
.payment-method:hover{

    border-color: #c9c9c9;
}

.productsTable tr td.product:hover{

    border-radius: 10px;
    overflow: hidden;
    padding: 4px;
}
.productsTable tr td.product:hover {
    box-shadow: -2px 1px 14px -1px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: -2px 1px 14px -1px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: -2px 1px 14px -1px rgba(0, 0, 0, 0.16);
}
.productsTable table tr td{
    border:none;
}
.productActions .amount{
width:20px;

}
.productActions input{
    width:80px;
    text-align: center;
    font-weight: bold;
    border: #e8e7ec57 1px solid;
    background: #e8e7ec1a;
    padding: 10px;
    border-radius: 20px;

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.productActions div.plus,.productActions div.minus{
    width:30px;
    height:30px;
    border-radius: 30px;
    color:#fff;
    font-size: 20px;
    cursor: pointer;
    color:#ed7d31;
    border:1px solid #e8e7ec;
    text-align: center;

}

.header-menu .material-icons.menu{
 color:#ed7d31;

}
.listitem{

  cursor: pointer;


}
.listitem:hover .goto{
color:#ed7d31;

}
.languages-drop div{

padding:14px;

}
.languages-drop div:hover{

background:#efefef;

}
.goto{
    width:50px;
    height:50px;
    border-radius: 30px;
    color:#fff;
    font-size: 20px;
    cursor: pointer;
    color:#efefef;
    text-align: center;

}
.material-icons.menu{


position:absolute;

top:inherit;
left:14px;
font-size:30px;
cursor:pointer;
color:#000!important;
padding:4px;

z-index:1;
}
.szh-menu-container.main-menu{

z-index:999;
}
.optionsHolder,.optionsHolderName {
  position: relative;
  width:100%;
}
.optionItem .material-icons{
  position: absolute;
top: 0;
bottom: 0;
right: 0;
margin: auto;
height: 24px;

}
.optionText{
  position: relative;
}
.image-container.logo img{

opacity:0.0;
}
.image-container.logo.loaded img{
animation: fadein 0.4s;
    animation-iteration-count:1;
    animation-fill-mode: forwards;
    background:#fff;
}
.loadmore{
border-radius:4px;
border:1px solid #ccc;

  -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor:pointer;
}
.cart-button{


position:absolute;
bottom:10px;

height:20px;

left:0;
right:auto;
}
.optionItem{
  padding: 10px;
  margin:10px;
  text-align: center;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,f2f2f2+100 */
  background: rgb(238,238,238); /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(238,238,238,1) 0%, rgba(242,242,242,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(238,238,238,1) 0%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(238,238,238,1) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#f2f2f2',GradientType=0 ); /* IE6-9 */
  border-radius: 10px;
  border:1px solid #ccc;

cursor: pointer;
width:225px;
max-width:250px;
margin:auto;
margin-bottom:10px;
}
.
.optionItem:hover{
    border:1px solid #efefef;
}
.optionItem .optionText{
    margin-top: 6px;
}
.goto{
    padding: 4px;
}
.productActions .plus{

    float: right;
}
.productActions div.minus{

}
td{
    font-weight: normal;
    font-size: 12px;
    text-align: left;
}
.productimg img{
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;

}
@keyframes placeHolderShimmer{
    0%{
        opcity: 0.0;
    }
    50%{
        opcity: .4;
    }
    100%{
        opacity:0.0;
    }
}
.title-container {
    margin-bottom: 1em;
    margin-top:40px;
}
.card .title {
    text-align: center;
}
.input-container {
    margin-bottom: 0.5em;
}
.input-group-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.form-link a {
    font-size: 14px;
}
.qr-code {
    width: 200px;
    margin: 0 auto;
}
.qr-code img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}
.downloads {
    display: flex;
    flex-direction: row;
    margin: 1em 0;
}
.downloads .grand-stream {
    flex: 0 0 50px;
    margin-right: 10px;
}
.downloads .description {
    text-align: left;
}
.loader{
    width:100px;
    margin:auto;
}
.payment-explain{
    font-size: 12px;
    font-weight: normal;
}
.street-field.name,.street-field.lastname{
    width:calc(50% - 10px);;
    float: left;

}


.street-field.lastname{
    width:calc(50%);;
    margin-left: 10px;
}
.label{
    display: inline-block;
    margin-top: 20px;
    font-size:14px;
    text-align: left;
    width: 100%;
    text-shadow:1px 1px 1px #fff;
}
.loadingShop{
    width:200px;
    margin:auto;
    text-align: center;
    margin-top: 14px;
}
.stores {
    display: flex;
    justify-content: space-around;
}
.stores .image-container {
    flex: 0 0 50%;
}
.stores .image-container:first-child {
    flex: 0 0 40%;
}
section .image-container.logo {
    position: relative;
    left: unset;
    top: unset;
    margin: 0 auto 20px;
}
section .title {
    font-size: 40px;
    line-height: 1.5em;
    margin: 0.5em 0;
}
section .text-container {
    margin: 1em 0;
}
a.button {
    display: inline-block;
    width: auto;
    padding: 0 40px;
    text-decoration: none;
}
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: var(--white-background-color);
}
.container {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 1080px;
    margin: 0 auto;
}
header .image-container.logo {
    position: relative;
    left: unset;
    top: unset;
    width: 60px;
    height: 60px;
}
header .container {
    display: flex;
    align-items: center;
}
.content-wrapper.fixed-header {
    padding-top: 70px;
    padding-bottom: 25px;
}
section .qr-code {
    margin: unset;
}
.app-download {
    display: inline-block;
    margin: 0.5em 10px 0.5em 0;
}
section .paragraph {
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
}
section h2 {
    margin-bottom: 10px;
    border-left: 4px solid var(--primary-color);
    padding-left: 10px;
}
section em {
    font-weight: 700;
}
.pull-right {
    margin-left: auto;
}
.button-group-container {
    display: flex;
    justify-content: space-between;
}
.button.small {
    font-size: 14px;
    padding: 0 20px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
}
.flex {
    display: flex;
    flex-direction: row;
}
.flex.space-between {
    justify-content: space-between;
}
.text-left {
    text-align: left;
}
.mr-10 {
    margin-right: 10px;
}
.mb-10 {
    margin-bottom: 10px;
}
@media screen and (max-width: 980px) and (max-height: 425px) {
    .content-wrapper {
        padding: 40px 1em 1em;
        background-color: var(--white-background-color);
    }
    .card,
    .content-wrapper {
        display: block;
        margin: 0 auto;
    }
    .card {
        box-shadow: none;
    }
    .card.shop {
    padding-top: 50px;
    width: 400px;
    }
}
.checkout-form ,.productrow {
  background: #FFF;
}
.card{
  background-color:rgba(255,255,255,0.97)!important;
}

h1 {
    font-size: 2em!important;
    margin: .67em 0;
}
.material-icons.chat{

    position:absolute;
    top:0;
    left:-40px;
    right:auto;
padding:10px;
}

.bubble-left .material-icons.chat{

right:-40px;
left:auto;
}
@media screen and (max-width: 425px) and (max-height: 980px) {
    .content-wrapper {
        padding: 40px 1em 1em;
        display: block;
        margin: 0 auto;
        background-color: var(--white-background-color);
        min-height: 100%;
    }
    .home-item .img-fluid{

width:80%;
height:auto;
    }
    .card {
        padding: 1em;
        width: 100% !important;
        display: block;
        margin: 3em auto 0;
        box-shadow: none;
    }
}
.recharge-amount{
  width:120px;
  margin: auto;
}
.form-label.currency{
  float: left;
      margin-top: 15px;
      font-size: 25px;
      font-weight: normal;
}
#amount,.mikari-amount{
  padding: 10px;

  text-align: center;
  font-size: 18px;
  border-radius: 20px;
  width: 100px;
  margin-top:10px;
  padding-left: 17px;
      font-weight: bold;


              border:1px #ccc solid;
}
.bubble-left,
.bubble-right {
    line-height: 100%;
    display: block;
    position: relative;

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f1f1f1+50,e1e1e1+51,f6f6f6+100;White+Gloss+%231 */
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(241,241,241,1) 50%, rgba(225,225,225,1) 51%, rgba(246,246,246,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(225,225,225,1) 51%,rgba(246,246,246,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(225,225,225,1) 51%,rgba(246,246,246,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */

    border: #ccc solid 1px;
    -webkit-border-radius: 11px;
    -moz-border-radius: 11px;
    border-radius: 11px;
    margin-bottom: 20px;
    clear: both;
    padding:10px;;
font-size:12px;
font-weight:none;
-webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.05);
box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.05);
text-align:left;
text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(255,255,255,.5);
}
.material-icons.send.loading{

 animation: elastic-spin 0.4s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;

}
.message-date{

color: #666;
font-weight:none;

}
.bubble-left {
    float: left;
    margin-right:10%;
       border-top-left-radius:0px;
}
.bubble-right {
text-align:right;
    float:right;
    margin-left:10%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#cdeb8e+0,a5c956+100&0.15+0,0.15+100 */
background: -moz-linear-gradient(top,  rgba(205,235,142,0.15) 0%, rgba(165,201,86,0.15) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(205,235,142,0.15) 0%,rgba(165,201,86,0.15) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(205,235,142,0.15) 0%,rgba(165,201,86,0.15) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26cdeb8e', endColorstr='#26a5c956',GradientType=0 ); /* IE6-9 */
   border-top-right-radius:0px;
}
.SubmitButton{
    background-color:#ed7d31!important;
    margin-top: 14px!important;
    max-width:200px;
    margin:auto!important;
    width:200px;
}
.mikari-amount{
    width:120px;
  background-size: 20px 20px;
  background-position: 6px;
  background-repeat: no-repeat;
  -webkit-box-shadow: none;
  box-shadow: none;
  border:1px #ed7d31 solid;
}
.climate-img{
    width:50px;
    height:50px;
    background-size:50px auto;
    background-position: left top;
    background-repeat: no-repeat;
    -webkit-transition: all 0.1s ease-in-out; /* Safari */
    animation: fadeIn 0.4s;
    animation-iteration-count: 1;
}
.climate-holder.mini .climate-img{

    width:30px;
    height:20px;
    background-size:30px auto;
    background-position: left top;
 position:absolute;
   top:0;
   left:0;
   bottom:0;

   right:0;
   margin:auto;
}
.climate-holder .climate-img{
    margin: auto;
    width:50px;

    margin-top: 0px;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.preferred-delivery .material-icons:hover{
border:1px solid #ccc;

}
.category_headers{


}
.material-icons,.title-container,.checkout-form,.qrview,.testmode,.header,.results,.leaflet-container,.category_headers{
   -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.mikari-ideal{
    width:100px;
  background-size: 100% auto;

  background-repeat: no-repeat;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: absolute;
  right:4px;
  top:4px;
  height: 100px;

}
.title,.image-container.logo img{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.tpu-amount{
  margin-top:20px;
}
.checkout-table{
  margin-top:17px;
  width:100%;
  font-size: 12px;
  font-weight: normal;

}
.checkout-table td{
  text-align: left;
  margin-left: 10px;
}
.checkout-table .tlab{
  width: 50%;
  text-align: right;
}
.results{
  width:270px;
  min-height:270px;
    height:auto;
  margin:auto;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 50% auto;
}
.form-label.tpu-amount{
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.form-outline.recharge-amount.fixed{
    padding:10px;

    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    margin-bottom:10px;
}
.noproducts{


    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 30% auto;


}
.qrview{
    cursor: pointer;
    margin-top: 30px;
}
.qrview .material-icons{
    font-size:30px;
    cursor: pointer;
}
.results.error{

}
.counter{
    margin:auto;
    text-align:center;
    display: inline;
}
.counter div{
    margin: auto;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.material-icons.preparing{
    animation: bounceInFade 2.4s;
    animation-delay: 1.3s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    font-size:50px;
    color:#ed7d31;
}
.material-icons.done{
    animation: elastic-spin 2.4s;
    animation-delay: 1.3s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    font-size:50px;
    color:#ed7d31;
}
.pickup-loc{
    font-size:19px;
}
.material-icons.maps{
    font-size:30px;
    color:#ed7d31;
}
.plan-trip{

    margin-top:30px;
    text-decoration: none;
}
.plan-trip:hover{
    color:#ed7d31;
    cursor: pointer;

}
.material-icons.delivering{
    animation: shake 30.4s;
    animation-delay: 1.3s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    font-size:50px;
    color:#ed7d31;
}
@keyframes elastic-spin {
    0% { transform: rotate(10deg); }
    50% { transform: rotate(-10deg); }
    90% { transform: rotate(0deg); }
    100% { transform: rotate(10deg); }
}
@keyframes shake {
    0%, 100% {opacity:0;transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {opacity:1;transform: translateX(-10px);}
    20%, 40%, 60%, 80% {opacity:1;transform: translateX(10px);}
}
@keyframes bounceInFade {
    30% { transform: scale(1.1); }
    40%, 60% { transform:scale(1.1); }
    50% { transform: scale(1.1); }
    70% { transform: scale(1.1); }
    100% { transform: scale(1); }
}
.gelatine {
    animation: gelatine 0.5s infinite;
}
@keyframes gelatine {

}
.results.success{
width:100%;
}
.success{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30% auto;



}
@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}
.loadmore.loading{

 animation: fadeinout 1.4s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}
@keyframes fadeinout {
    from { opacity: 0; }
    to { opacity: 1; }
}
.error .material-icons{

    color:#ed7d31;
}
.goto.material-icons{
font-size: 40px;
}
.paytext .material-icons{
    margin-top: 10px;
}
.material-icons.pay{
position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 12px;
    height: 25px;
    width: 25px;
}
.translated-text-pay{



}
.paytext{

}
.translated-text-pay{
}
.header-menu .menu{


    cursor:pointer;
}

.error{
    border: 1px dashed #ed7d31;
    border-radius: 6px;

    margin-bottom: 10px;
    color: #000;
    font-size: 14px;
    margin: 8px;
    padding: 4px;
    display: flex;
    animation: fadein 0.4s;
    animation-delay: 0.3s;
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
}
.error .label{

    font-size: 14px!important;
    float: left;
    margin-top: 4px !important;
    text-transform: none;
}
.flip-card {
    background-color: transparent;


}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
   /* transition: transform 0.6s;
    transform-style: preserve-3d;*/

}

.flip-card.flipped .flip-card-inner {
   /* transform: rotateY(180deg);*/
}

.flip-card-front, .flip-card-back {

    width: 100%;
    height: 100%;
    /*-webkit-backface-visibility: hidden;*/
    /*backface-visibility: hidden;*/
}

.flip-card-front {

    color: black;
}

.flip-card-back {


  /*  transform: rotateY(180deg);*/
}
.form-outline.recharge-amount{
    margin-top:10px;
    width:100%;
    margin-bottom:10px;
}
.form-outline.recharge-amount input{
    font-size:16px!important;
     text-align: center!important;
    padding:6px;
    border:1px solid rgba(131, 131, 131, 0.57);
    border-radius: 6px;


}
.react-tel-input .flag-dropdown{
    border: 1px solid #717171!important;
}
.preferred-delivery .material-icons{

    opacity: 1;

    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    border:1px solid #111;
}
.home-item{
    opacity: 1;
    -webkit-transition: all 2s; /* Safari */
    animation: slideIn 1.4s;
    animation-iteration-count: 1;
    margin-top: 0px;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.checkout-mini{
    opacity: 1;
    -webkit-transition: all 0.2s; /* Safari */
    animation: slideIn 0.2s;
    animation-iteration-count: 1;
    margin-top: 0px;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.lightbox .material-icons{
position:absolute;
z-index:9999;
cursor:pointer;
right:8px;
top:25px;
font-size:40px;
border-radius:30px;
   -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    box-shadow: 0 2px 2px rgba(59, 59, 59, 0.4);
    background:#fff;
    color:#000;
}
.pdesc{

    position: absolute;
    z-index: 999;
    top: 114px;
    width: 100%;
    background: #fff;
    font-size: 20px;

    overflow: hidden;
      overflow-x: auto;
      padding:10px;

}
.lightbox img{

position:absolute;
width:100%;
max-width:500px;
margin:auto;
top:0;
bottom:0;
left:0;
right:0;
  animation: fadein 2.4s;
  animation-delay: 0;
  animation-iteration-count:1;
}
.lightbox{

position:fixed!important;
top:0;
left:0;
z-index:9999;
background-color:rgba(255,255,255,1.0);
width:100%;
height:100%;
display:block;
  animation: fadein 0.1s;
  animation-delay: 0;
  animation-iteration-count:1;
  color:#000;
}
@keyframes slideIn {
    0% { opacity: 0;margin-top:1000px; }
    20% { opacity: 0;margin-top:100px; }
    100% { opacity: 1;margin-top:0px; }
}
.postcode-field,.street-field,.housenr-field,.phone-field,.pac-target-input{
    height: 40px;
    border: 1px solid #ccc!important;
    border-radius: 4px!important;
    padding: 10px;
    font-size: 20px;
    width: 140px;
    text-align: center;
    font-family: Nunito!important;


}

.search-name{
    width:100%;
    font-size:14px;
}
.react-tel-input .form-control{
    border: 1px solid #717171!important;
    border-radius: 4px!important;
}
.react-time-picker__inputGroup__input:invalid{
    background: none!important;
}
.street-field .hidden{

    display: none;
}
.pac-target-input{
    width: 100% !important;
 }
.street-field{
    width:100%;
}
.pac-target-input,.react-tel-input .form-control{
    width: 100%!important;
}
.phone-field{
    width:100%;
}
.housenr-field{

    width:100px;
}
.voucher-field{

}
.shippingform,.phoneform{
    padding:4px;
}
.reduction-label{
    padding: 4px;
    border: 1px dashed #e5972a;
    border-radius: 4px;
    font-size: 14px;
}
.material-icons.reduction{
    float: left;
    margin-top: 2px;
    color: #e5972a;
}
.housenr-field.voucher{
    min-width: 200px;
}
.street-field,.housenr-field {

    font-size: 14px;
}
.incl_amount{
  font-weight: normal;
  font-size: 12px;
}
code.vat{
  font-size: 14px;
display: inherit;
}
.add-voucher-link{
margin-top:10px;
}
.shippingform{

}
.shippingform input{
    margin-top:10px


}
.art_image{
  position: fixed;

  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index: -1;
  animation: fadein 2.4s;
  animation-delay: 0;
  animation-iteration-count:1;
  animation-fill-mode: forwards;


}
.label.powerd {
    color: rgba(26,26,26,.2);

    font-weight: 400;

    font-size: 12px;

    bottom: 0;
    left:4px;
}
.label{

    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    text-transform: uppercase;
}
.edit-checkout{
    float:right;
    padding: 8px;
}
.edit-back{
    float:left;
    padding: 8px;
}
.cartCount{
    position: absolute;
    bottom: 17px;
    right: 17px;
    height: 22px;
    font-size: 10px;
    color: #000;
    padding: 3px;
    border-radius: 33px;
    text-align: center;
    min-width: 22px;
    border: 1px solid #e5972a;
    background: #fff;

    text-shadow:none;
    transition: all 0.2s ease;

    box-shadow: 0 2px 2px rgba(59, 59, 59, 0.4);
    opacity: 0.9;


}
.languageHolder{
position:absolute;
    top:4px;;
    right:4px;
z-index:101;
cursor:pointer;
}
.changelang{

position:relative;
width:180px;

z-index:100;
    margin:auto;
    margin-bottom:10px;
    margin-top:1px;
}
.postcode-field.startscreen{

margin-top:10px!important;
text-transform: uppercase;
margin-bottom:10px!important;
}
.material-icons.printer{

font-size:24px;
text-align:left;
cursor:pointer;
}
.currency_label{

position:absolute;
top:0;
right:0;
font-size:10px!important;
border:none!important;
border-radius:4px;
background:#efefef;
height:14px;
width:30px;
padding:1px!important;
}
.item-specs .material-icons{

color:#f59400;
font-size:22px!important;
margin-top:4px;
text-shadow:1px 1px 1px #efefef;

}
.old-price{


 text-decoration-line: line-through;
 color:#efefef;

}
.category_headers{
padding:4px;

margin:auto;
margin-top:20px;
font-weight:bold;


    text-transform: uppercase;
font-size:16px;
}
.info-items table{

width:100%;

}
.review-item{


/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f3f3f3+50,ededed+51,ffffff+100;White+Gloss+%232 */
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(243,243,243,1) 50%, rgba(237,237,237,1) 51%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 50%,rgba(237,237,237,1) 51%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 50%,rgba(237,237,237,1) 51%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
padding:10px;
margin-top:4px;
border:1px solid #efefef;
margin-top:14px;

}
.material-icons.review{
float:left;
}
.reviews_bar{
position:relative;

cursor:pointer;
width:100px;
margin:auto;
height:20px;
}
.reviews-count{

font-size:10px;

}
.review-item .reviews,.review-item .nick_name{

white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.review-item .date{

font-size:10px;
text-align:right;

}
.review-item .nick_name{



text-align:left;
}
.review-item{
text-shadow:2px 2px 2px #fff!important;

cursor:pointer;
 animation: bounceInUp 0.4s;
    animation-iteration-count: 1;
}
.material-icons.reload{

font-size:30px;
cursor:pointer;
margin:12px;

}
.item-specs div{

margin-left:10px;
float:left;

}
.info-items table tr td ,.info-items table tr td a{
text-align:center;
margin:auto;
}
.info-items{

margin-top:30px;

}
.category_holder{
border:1px solid #efefef;;
position:relative;

left:0;
background:#fff;
padding:10px;

z-index:10;
right:0;
max-width:300px;
margin:auto;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    margin-bottom:10px;
}
.category_holder div .material-icons{
font-size:30px;
position:absolute;
top:0;
left:0;

}
.category_holder div .title{
margin-left:30px;
text-align:left;
margin-top:4px;
}

.category_holder div:hover .material-icons{

color:#f59400;

}

.categoryLabel .material-icons{
font-size:30px;
text-align:center;
}
.categoryLabel{


}
html{

}
.product a{

text-decoration:none;
}
* ,html{
scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}
.home-item table td{
padding:10px;
text-align:center;
}

.categoryLabel{
margin: auto;
    width: 300px;
text-align:center;
}
.categories .countryLabel{

text-align:center;

margin-bottom:10px;
}
.category_holder div:hover{

background:#efefef;


}
.category_holder div{
cursor:pointer;
position:relative;
text-align:left;
padding:4px;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  height:40px;
}
.categories{


position:relative;

}

.bar{
margin-bottom:10px;
background:#FFF;
border-radius:10px;
padding:10px;
margin-top:20px;

    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
position:relative;

}


.footer-links{

display: flow-root;
    width: 200px;
    margin-bottom: 40px;
}

.share-all{

color:#000;
margin:40px;
}
.home-item table{


margin:auto;
}
.hidedrops{

width:100%;
height:100%;
top:0;
left:0;

position:absolute;
position:fixed;
}
.countryLabel{

    cursor: pointer;
    background: #fff;
    border-radius: 10px;
    max-width: 200px;
    margin: auto;
    position:relative;
}
.homecountries{

font-size:12px;

}
.countryLabel div span,.homecountries span{


margin:4px;

}
.countryLabel div{

    padding: 10px;
    border:1px solid #efefef;
    border-radius: 10px;
    font-size:12px;
    font-weight:normal;

}
.homecountries{

    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    width: 180px;
    position: absolute;
    z-index: 9;
    background: #fff;
    box-shadow: 0 2px 2px rgb(59 59 59 / 40%);
    border-radius: 4px;
    left: 0;
    top: 0px;
}
.homecountries div:hover{

background:#efefef;

}

.homecountries div{

    padding:6px;
    border-bottom:1px #ccc solid;
    cursor:pointer;
   -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.homecountries::before{

    width:100%;
    height:100%;
    left:0;
    top:0;
    background:rgba(0,0,0,1);
    position:fixed;

}
.cartCount:hover{
    opacity: 1.0;
    border: 1px solid #e5972a;
    background: #fff;
    color: #000;
}

.postcode-field:hover, .street-field:hover, .housenr-field:hover, .phone-field:hover, .pac-target-input:hover,
.postcode-field:focus, .street-field:focus, .housenr-field:focus, .phone-field:focus, .pac-target-input:focus{
    border-color: #000!important;
}
.postcode-field:hover, .street-field:hover, .housenr-field:hover, .phone-field:hover, .pac-target-input:hover{
    border-color: #111 !important;
}

.label .material-icons,.cartCount{
cursor: pointer;

}
.closed{
    display:none;
}
.selectedName{
    padding:10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border:1px solid;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;

}
.label.goback .edit-checkout{
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    color: #e5972a;
    position: absolute;
    bottom:0;
    right:0;

}
.label.goback .edit-checkout.home {


}
.bar .label{
 text-shadow: none!important;;

}
.label.goback .edit-checkout.home{
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    color: #000;
     box-shadow: none;;
    position: absolute;
    bottom:auto;
    top:0;
    right:0;
    cursor: pointer;

}

.label.goback .edit-checkout{

left:0;
right:none;
}


.label.goback .label{
margin-top:0px;
margin-bottom:10px;
text-align:center;
}

.edit-back{
    font-size: 22px!important;
    position: absolute;
    top:10px;
    left:-10px!important;

}
.bar .label{



    text-shadow:1px 1px 1px #e8e7ec!important;
}

.voucher-check,.phoneform{
text-align: left;
}
.voucher-icon{

   float:right;
    margin-top: 8px;
    cursor: pointer;
    color: #0c5912!important;

}
.payment-method.p-ideal{
background-position: 0 -302px;
}
.payment-method.p-ideal{
    background-position: -10px -302px;
}
.payment-method.p-p24{
    background-position: -10px  -395px;
}
.payment-method.p-giropay{
    background-position: -10px  -209px;
}
.payment-method.p-card{
    background: none;
    background-position: -10px  -302px;
}
.payment-method.p-sofort{
    background-position: -10px  -302px;
}
.payment-method.p-eps{
    background-position: -10px  -116px;
}
.payment-method.p-bancontact{
    background-position: -10px  -23px;
}
.payment-method{
    position: relative;
    background: #fff;

}
.shipping-price-amount.hidden{
    height:0;

    overflow: hidden;
}
@keyframes bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-100px);
        display:none;
    }
    60% {
        opacity: 1;
        transform: translateY(-30px);
    }
    80% {
        transform: translateY(-10px);
    }
    100% {
        display:none;
        transform: translateY(0);
    }
}
@keyframes bounceInUp{
    0% {
        opacity: 0;
        transform: translateY(100px);
        display:none;
    }
    100% {
    opacity: 1;
        transform: translateY(0);
    }
}
.payment-method .material-icons{

    position:absolute;
    right:4px;
    top:4px;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    color: #e8e7ec;
}
.payment-method .cc{
    top:-2px;
    font-size:34px;
    left:0;right:auto;
}
.payment-method .cc::after{
color: #ed7d31;
    content:'\e897';
font-size:10px;
    right:3px;
    position:absolute;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    z-index: 9;
    background: #fff;
    bottom:6px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid #ed7d31;
}
.pm-name{
    font-size: 14px;
    font-family: Nunito!important;
    margin-left: 40px;
    margin-top: 4px;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.payment-method{
    padding:4px;
    background-size: 60px auto;
    background-repeat: no-repeat;
    border:1px solid #dadada;
    margin:4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    text-align: left;
    height: 32px;
    cursor: pointer;
    margin-top: 8px;
    position: relative;

}
.payment-methods{

    padding: 4px;
    background: #e8e7ec57;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
payment-methods.hidden{

}
.preferred-delivery .react-time-picker__wrapper{
    padding: 4px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.preferred-delivery{

     padding: 17px;
    /* padding: 4px; */
    margin-top: 10px;
    border: 2px solid #efefef;
    background: #fcfcfc;
    border-radius: 6px;
    margin-left:40px;
    margin-right:40px;
 }
.shippingform .housenr-field.addition{
    margin-left:10px


}
.minimum-price-amount{

}
.header{

    font-size: 12px;
    font-weight: normal;
}
.delivery{

    float: left;
    margin-top: -3px;
}

.shipping-price-amount{

    text-align: left;
    max-width:320px;
    margin:auto;
height:auto;
}
td{
    text-shadow:1px 1px 1px #e8e7ec;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.plus,.minus,.goto{
    text-shadow:none;
}
.plus:hover,.minus:hover{
   border:1px solid #e5972a;
}
.plus:hover,.minus:hover{
    border:1px solid #e5972a;
}
.postcode-change{
    margin-bottom: 10px;
    border-bottom: 2px solid #000;
    padding:10px;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;

    margin:auto;
    margin-bottom: 30px;
    max-width:100px;

    text-transform: uppercase;
}

.card.shop{
    animation: fadein 0.8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

}
.order-again{

margin-bottom:10px!important;
}
.deliver.direction_open{
transform: rotate(-90deg);
 -webkit-transition: all 0.5s linear; /* Safari */
}
*:focus{
outline: none;
}
.climate-holder img{
    top:0;
    left:0;
    width:40px;
}
.climate-holder.mini{
    width:50px;
    border:none;
    background: none;
height:50px;
    box-shadow: none;
    background:#fff;
}
.climate-holder.mini img{
   width:20px;
   height:20px;
   position:absolute;
   top:0;
   left:0;
   bottom:0;

   right:0;
   margin:auto;

}
.climate-holder{
    color: #717171;
    background: #fff;
    text-align: center;
    padding:8px;
    border:1px solid #e8e7ec;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 12px;
    width:140px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    bottom:4px;
    right:38px;

    box-shadow: 0 2px 24px rgba(59, 59, 59, 0.4);
}
.footer,.minimum-price-amount,.shipping-price-amount{

    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    text-shadow:1px 1px 1px #fff;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;

}
.minimum-price-amount,.shipping-price-amount,.email-holder,.name-holder{

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 4px;


}
.react-tel-input .form-control{
    width:100%;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}



.lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 0%;
    background: #f3941a;
    animation: lds-grid 1.2s linear infinite;
}
.miniloader .lds-grid{
    zoom: 0.4;
}
.miniloader{
    position: absolute;
    right:2px;
    top:4px;
}
.material-icons.edit-checkout.home{


    border-radius: 25px;
    width: 50px;
    height: 50px;
    color: #000;
    animation: slideUpIn 1.4s;
    animation-iteration-count: 1;
    text-align: center;
    font-size: 30px;
}
.searchbar input,.searchbar .pac-target-input{
    animation: slideUpIn 1.4s;
    animation-iteration-count: 1;
    max-width:280px!important;
    text-align:center!important;
    padding-left:10px;
}
.item_description{



}
.item_title{


font-size:20px;
}
@keyframes slideUpIn {
    0% { opacity: 0;}
    30% { opacity: 0; }
    100% { opacity: 1; }
}



.miniloader.ellipsis {
    position: relative!important;
    top: auto;
    left: auto;
    right: auto;
}
.miniloader.ellipsis .lds-ellipsis div {
    background-color: #ff931c;

}
.material-icons.edit-checkout.home:hover{

    /*border:1px solid #e5972a;
    color: #fff;
    background: #e5972a;
    text-shadow: none;*/
}

.searchbar input{
    padding-right: 40px!important;
}

.searchbar{
    position: relative;
        position: relative;
    width: 300px;
    margin: auto;
    max-width:calc(80vw)!important;
}
.label.title_search{
margin-top:10px!important;

}

.productsTable{

margin:auto;
}
.categoryLabel .title{

font-size:10px;
}
.searchbar input,.categories .categoryLabel,.listitem{
    max-width:calc(80vw)!important;
    }
.lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}
@keyframes lds-grid {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-top: -18px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.4s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.4s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.4s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.4s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}


.progressHolder{
    width: 200px;
    height: 4px;
background:#ccc;
    border-radius: 10px;
position:relative;
margin-top:20px!important;
margin-bottom:20px!important;
}

.start{
  width:24px;
    height:24px;
    border-radius:20px;
    overflow:hidden;
    position:absolute;
     background:#ccc;
      margin:auto!important;
    top:0;
    left:0;
    bottom:0;
}
.shipped{
  width:24px;
    height:24px;
      border-radius:20px;
    overflow:hidden;
    position:absolute;
      background:#ccc;
     margin:auto;
    top:0;
    right:0;
    bottom:0;
}
.start.active,.progressHolder .shipping.active,.shipped.active{
background:#ed7d31;
color:#fff;


     animation: bounceInFade 2.4s;
    animation-delay: 1.3s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;


}
.start.active.stopped,.progressHolder .shipping.active.stopped,.shipped.active.stopped{
  animation: none;
}

.progressHolder .shipping{

    border-radius:20px;
    overflow:hidden;
    position:absolute;
    background:#ccc;
    margin:auto!important;
    right:0;
    bottom:0;
    top:0;
    left:0;

}

.material-icons.rnshare{
font-size:42px;
width:42px;
margin:auto;
display:flex;
cursor:pointer;
}

img {
   user-drag: none;
   user-select: none;
   -moz-user-select: none;
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}

.maps.deliver{
position:absolute;
top:0;
right:0;
cursor:pointer;

    -webkit-transition: all 0.3s ease-in-out; /* Safari */
}
address{



}
.messages-title{
position:relative;
text-align:left;
padding:4px;
padding-left:30px;
background:#fcfcfc;
border-radius:4px;
}
.msgcount{
font-size:12px;
position:absolute;

top:0;
bottom:0;
margin:auto;
height:12px;
left:0;
color:#fff;
border-radius:10px;
padding:2px;
background-color:#000;
}
.messages-title .material-icons.arrow{

position:absolute;
right:0;
top:0;
margin:auto;
bottom:0;
height:24px;
}
.material-icons.resetrating{
position:absolute;

width:14px;
font-size:14px;
top:4px;
right:4px;


}
.SubmitButton{
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.messages-title .material-icons.mail{


position:absolute;
left:0;
top:0;
bottom:0;
margin:auto;
height:28px;
}
#home{

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffb76b+0,ffa73d+50,ff7c00+51,ff7f04+100;Orange+Gloss */
background: #ffb76b; /* Old browsers */
background: -moz-linear-gradient(-45deg,  #ffb76b 0%, #ffa73d 50%, #ff7c00 51%, #ff7f04 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  #ffb76b 0%,#ffa73d 50%,#ff7c00 51%,#ff7f04 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  #ffb76b 0%,#ffa73d 50%,#ff7c00 51%,#ff7f04 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffb76b', endColorstr='#ff7f04',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
position:absolute;
width:100%;
height:100%;
z-index:0;
}
.rating-field{

margin:auto;
display:inline-block;

}
.rainting-box{

display:block;

}
.rainting-box .SubmitButton{

background-color:#fff!important;
box-shadow:none;
border:1px #ccc solid;
color:#000;

}
.head-bttn{


cursor:pointer;

}
.shop-bttn{
color:#ed7d31;
}
.shop-menu{

margin-top:20px;

}
.qr_txt{
    background-position: top;
    background-repeat: no-repeat;
    background-size: 80% auto;
    border-radius: 10px;
    bottom: 25px;
    height: 36px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 280px;
    opacity: 0.3;
    z-index:-1;
    display:none;
}

.share-all .goto{


    color:#000;

}
.autosuggest-list{

width:400px;
overflow-x:hidden;
overflow-y:auto;
padding:4px;
background:#FFF;
max-width:calc(80vw);
z-index:999;
top:30px;
margin:auto;
left:0;
right:0;
border:1px #efefef;
    box-shadow: -2px 1px 14px 1px rgba(0,0,0,0.4);
    -webkit-box-shadow: -2px 1px 14px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: -2px 1px 14px 1px rgba(0,0,0,0.4);
    border-radius:10px;
}
.autosuggest-osm{

position:relative;
width:100%;
}
.autosuggest-list-item:hover{


background:#efefef;

}
.autosuggest-list-item{
cursor:pointer;

height:40px;
padding:10px;
font-size:14px;
font-weight:bold;
display:list-item;
overflow-x:hidden;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.route-name.km{

margin:auto;
width:100px;
margin-bottom:10px;
}
.leaflet-top.leaflet-right{
display:none;
}
.summary{
width:100%;

background:rgba(255,255,255,0.8);
top:0;
z-index:3000;

}
img.leaflet-marker-icon{

border-radius:10px;

overflow:hidden;


}
.SubmitButton{


margin-top:4px!important;
margin-bottom:4px!important;
}
.SubmitButton.scan_confirm_shipping,.SubmitButton.confirm_shipping{
margin-top:10px!important;
}
.progressHolder{

margin:10px;

}
.material-icons.rnshare{


}
.tracknum{

font-size:40px;
    text-transform: uppercase;

         -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;

}
.progressHolder .start,.shipped,.shipping{
width:50px;
height:50px;
border-radius:25px!important;
}
.langloc{
font-size:10px;

}
.summary_table * tr td{
vertical-align:middle;
height:30px;
position:relative;
font-size:12px;
}
.dtime{
border-radius: 10px;
    border: 1px solid #000;
    padding: 4px;
    font-size:18px;

}
.summary_table * tr td.pn{
padding-left:32px;
font-size:18px;
}

.material-icons.maps{
color:#1a73e8;
font-size:40px;
margin:10px;
}
.label{
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.confirmed-shipping{

      border-radius:40px;
    overflow:hidden;

      background:#ccc;
     margin:auto;
cursor:pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;

}
.minimum-price-amount.shipping{

width:80%;
padding:10px;
margin-left:10px;
}
.material-icons.phone.share{
font-size:40px!important;
cursor:pointer;
}
.line-img{
position:absolute;
top:0;
bottom:0;
margin:auto;
left:0;
}
.progressHolder div .material-icons{

position:absolute;
top:0;
left:0;
right:0;
bottom:0;
margin:auto;
width:30px;
height:30px;
font-size:30px;
}
.subtotal.title{

font-size:12px;
text-align:right;
padding-right:10px;
}
.active.done{

animation: none!important;
}
.progressbar{
background:#ed7d31;
height:5px!important;
}
.progressbar{
position:absolute;
top:0;
left:0;
}
.map{

width:100%;
height:140px;
}
.material-icons.printer{


position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 28px;
}
.qty input{

text-align:center;
}
.home-item td{
vertical-align:middle;

}

@media screen and (max-width: 368px) {
.optionText .material-icons {
display:none;
}
        .productimg{

        width:50px;
         height:50px;
        }
        .optionItem{
       max-width:100px;
        }
        .optionHolder .optionItem{

        max-width:100px;
        }
       .street-field.comments{

    width: auto;
    min-width: 82px;
    max-width: 80%;
        }
        .productActions input{

        width:auto;
        }
        .home-item img{

        max-width:100px;
        height:auto;
        }
        .dtime{

        position:relative;
        background:none;
        }
       .pickup-check .material-icons{
margin:auto;

margin-bottom:10px;
       position:relative;

       }

       .label.powerd{

       }
       .material-icons.delivery{

       display:none;
       }
       .preferred-delivery{
margin-left:0px;
margin-right:0px;

       }
.pickup-check,.minimum-price-amount.shipping{
font-size:10px;
}
      table td div,.searchbar input, .categories .categoryLabel, .listitem, .productsTable table{


      max-width:200px;
      }
      .optionsHolder{

      margin:auto;
      }
      .productdesc{

      display:none;
      }
      .optionItem .optionText{
      margin-top:0;
      }
      .summary_table * tr td.pn{

      font-size:12px;
      }
}
.subholder .qty input{

width:20px;
}
.postcode-holder{
        max-width:400px;
        margin:auto;
    border-radius: 8px;
    }
@media screen and (min-width: 790px) {

    .card.shop{


    max-width:80%;
    border-radius:2px;
    }
    .flip-card-inner {

    transition: none;
    }
    .postcode-holder{
        max-width:400px;
        margin:auto;
    border-radius: 8px;
    }
    .productimgtd{
    width:100px;
    }
    .productname{

    font-size:2em;
    }
    .header{

    font-size:2em;
    }
#delivery-qr{

display:none;
}
.mini-cart-holder * .productimgtd{



}
    .productdesc,.pricetd,.SubmitButton{
    font-size:1.6em;
    }
    .productdesc{

    font-size:1.2em;
    }
    .special-price{


    color:#ff0000;
    }
     .SubmitButton {
    padding:20px!important;
     font-size:1.8em;
    }
    .climate-holder .climate-img{
background-size:60px;
width:120px;
background-position:center;
}
    .climate-holder.mini .climate-img{
    height:auto;
    width:100px;
background-size:60px auto;
background-position:center;
    }

    .preferred-delivery{

    max-width:400px;
    margin:auto;
    }
.flip-card {
    background-color: transparent;

    perspective: 9000px;
    transition:none!important;
}
.flip-card.flipped .flip-card-inner {
   /* transform: rotateY(180deg);*/
     transition:none!important;
}
.productimg{
width:100px;
height:100px;


}
}
.messagebox .SubmitButton {
    padding:10px!important;

    }


 .productsTable{
    width:100%;
    margin-bottom:20px;
}
.shop_nav{
position:absolute;
top:8px;
left:0;
z-index:1;
width:100%;

         -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.checkout-close{

}
.checkout-form {


border:2px solid #efefef;
position:relative;
margin-top:40px;
}
.mini-cart-holder .material-icons{

cursor:pointer;
}
.checkout-form  .productsTable{
width:228px;
max-width:228px;
}
.checkout-form  .productsTable .productimg{

margin:auto;
}
.floating-cart{

width:100%;

}
.mini-cart-holder{

position:relative;

}

.pricetd.floated{
position:absolute;
bottom:0;
left:0;
right:0;
margin:auto;
width:100%;
text-align:center;
font-size:12px;
background-color:rgba(255,255,255,0.6);
}
.shop_nav a{

text-decoration:none;
}
.shop_nav .szh-menu__item{

font-size:14px;
margin-top:4px;
}

.shop_nav button{
font-weight:bold;
padding:8px!important;
background:#fff;
border-bottom:1px solid #000;
margin-right:10px;
}
.shop_nav button:disabled{
font-weight:normal;
border-bottom:none;
}
.shop_nav table{
font-weight:bold;
border-spacing:0px;
}
.shop_nav td{
font-weight:bold;
}
#mini-cart{

    position: fixed;
    top: 30px;
    right: 22px;
 background:#fff!important;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.08), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #faf9fa;
  border-radius: 30px;
  width:80px;
  animation: bounceInFade 1.4s;
    animation-delay: 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    overflow:hidden;
    cursor:pointer;
             -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index:200;
    border:2px double #ed7d31;
}
#mini-cart.disabled .mini{

opacity:0.4;

}
.loadmore:hover {


}
.material-icons.mini{
  padding:4px;
margin-top:12px;
font-size:40px;
color:#000;
margin-bottom:4px;
}
#mini-cart.disabled .material-icons.mini,#mini-cart.disabled .mini-counter{

color:#ccc;

}
.mini-counter{
top:0;
position:absolute;
right:0;
border-radius:12px;
    min-width:40px;
padding:4px;
 text-align:right;
 margin-right:4px;
  margin-top:6px;
width:100%;

 font-size:12px;
}
.mini-count{

font-size:8px;
position:absolute;
bottom:0;
left:14px;
text-align:center;
width:70%;
left:0;right:0;
margin:auto;
text-overflow:ellipsis;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.carttable{

width:100%;
margin:auto;
   margin-top: 10px;
    background: #fcfcfc;
}
.carttable td{
padding:8px;
border:1px solid #efefef;

}
.material-icons.morebutton{
font-size:40px;
}
.material-icons.delete_{
float:left;

}
.carttable td.qty{

text-align:center;
}
.carttable td.qty{

width:20px;
}

.carttable .material-icons:hover{

color:#000;
}
.carttable .material-icons{

color:#ccc;
}
.cart-delete{

position:relative;
top:auto;
right:auto;
font-size:30px;

}
.filter-icon{

font-size:30px;
left:0;
width:30px;
margin:auto;

cursor:pointer;
}
.filter-item-icon{
position:absolute;
top:0;
left:0;
font-size:30px;
cursor:pointer;
}
.filter-item{

position:relative;
padding-left:30px!important;
}
.filter-menu{

    z-index:9999;

}

.foating-search{



position:fixed;
bottom:30px;
right:30px;
z-index:999;
font-size:40px;
padding:10px;
background:#fff;
color:#000;
border-radius:30px;
 background:#fff!important;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.08), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #faf9fa;
    cursor:pointer;
}

#search-input{
   padding-right: 40px!important;
padding-left: 40px!important;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}